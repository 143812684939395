<template>
    <div class="debugViewMain" v-if="!hide">
        <div class="debugInfo" v-html="sizes">

        </div>
    </div>
</template>

<script>
export default {
    name:'DebugUI',
    props:{
        hide:Boolean,
    },
    data(){
        return {
            cwidth:0,
            cheight:0,
            wwidth:0,
            wheight:0
        }
    }
    ,
    methods:{
        reflow:function(){
            this.cwidth = document.body.clientWidth;
            this.cheight = document.body.clientHeight;
            this.wwidth = window.innerWidth;
            this.wheight = window.innerHeight;
        }
    },
    computed:{
        sizes:function(){
            var c = '';
            c += 'media query: '+getComputedStyle(document.documentElement).getPropertyValue('--debug-name')+'<br>';
            c += 'body.clientWidth:'+this.cwidth+'<br>';
            c += 'body.clientHeight:'+ this.cheight +'<br>';
            c += 'window.innerWidth '+this.wwidth+'<br>';
            c += 'window.innerHeight '+this.wheight+'<br>';
            
            return c;
        }
    },
    mounted:function(){
        var self = this;
        self.reflow();
        window.addEventListener('resize', self.reflow, false);
        window.addEventListener('orientationchange', self.reflow, false);
    }
}
</script>

<style scoped>
.debugViewMain{
    z-index: 1900;
    background-color: red;
    opacity: 0.75;
    color: #fff;
    font-size: 1rem;
    position: absolute;
    top: 0px;
    left:0px;
    right: 0px;
    bottom: 0px;
}
</style>