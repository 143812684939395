<template>
    <div class="menubutton" @click="doClick">
        {{ title }}
    </div>
</template>

<script>
export default {
    name:'MenuButton',
    props:{
        title: String,
        value: String,
        isActive: Boolean
    },
    emits: ['click'],
    data(){
        return {
            
        }
    },
    methods:{
        doClick: function(){
            if(this.isActive)return;
            this.$emit('click',this.value);
        }
    }
}
</script>

<style scoped>
.menubutton{
    color: #fff;
    /* background-color: #666; */
    user-select: none;
    cursor: pointer; 
    transition: backgroundColor 0.6s; 
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>