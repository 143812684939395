<template>
    <div class="tspMain" v-if="shown" :class="{tspMainClosed : !isOpen}">
        <div class="textcontainer" ref="textcontent">
            <div class="textpage" v-for="(value,index) in contents" :key="index"  v-html="value" :class="{textoff:index!==currentpage}">
            </div>
        </div>
        <div class="pagination" v-if="isOpen && numpages>1">
            <div v-for="(index) in numpages" class="pagebtn" @click="page(index-1)" :key="index">
                <i v-if="index-1===currentpage" class="fas fa-circle"></i>
                <i v-else class="far fa-circle"></i>
            </div>
        </div>
        <div class="closeIcon" @click="toggleOpen" >
            <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 42.529 36" class="closeIconViewbox" :class="{openIcon:isOpen}">
                <g  transform="translate(42.529 36) rotate(180)">
                    <g  transform="translate(639.529 264) rotate(180)">
                        <path d="M1167.307.3l3.3-3.136,16.628,17.5-16.628,17.5-3.3-3.135,11.486-12.089h-28.045V12.386h28.045Z" transform="translate(1790.278 260.662) rotate(180)" fill="#fff"/>
                        <rect width="5" height="36" transform="translate(597 228)" fill="#fff"/>
                    </g>
                </g>
            </svg>
        </div>
        
    </div>
</template>

<script>
export default {
    name: 'TextSlidePager',
    props:{
        contents:Array,
    },
    data(){
        return {
            currentpage:0,
            isOpen: true,
        }
    },
    computed:{
        numpages:function(){
            return this.contents.length;
        },
        shown:function(){
            return (this.contents && this.contents.length > 0);
        }
    },
    watch:{
        contents(){
            //reset paging
            this.reset();
        }
    },
    methods:{
        page(p){
            this.currentpage = p;
            // var w = getComputedStyle(document.documentElement).getPropertyValue('--tsp-width');
            var w = this.$refs.textcontent.clientWidth;
            this.$refs.textcontent.style.left = "-"+ (p*parseInt(w)) + "px";
        },
        reset(){
            this.currentpage = 0;
            if(this.$refs.textcontent) this.$refs.textcontent.style.left=0;
        },
        close(){
            this.isOpen = false;
        },
        open(){
            this.isOpen = true;
        },
        toggleOpen(){
            this.isOpen = !this.isOpen;
        }
    }
}
</script>

<style scoped>
.tspMain{
  background-color: var(--m-secondary-color, #ccc);
  width: var(--tsp-width,675px);
  height: var(--tsp-height,480px);
  opacity: var(--tsp-opacity,0.8);
  position: var(--tsp-position, absolute);
  top: var(--tsp-pos-top);
  left: var(--tsp-pos-left);
  right: var(--tsp-pos-right);
  bottom: var(--tsp-pos-bottom);
  overflow: hidden;
  clip-path: var(--tsp-clip);
  transition: all 0.6s ease-in-out;
}
.tspMainClosed{
  top: var(--tsp-pos-top-closed);
  left: var(--tsp-pos-left-closed);
  right: var(--tsp-pos-right-closed);
  bottom: var(--tsp-pos-bottom-closed);
}
.textcontainer{
  width:100%;
  height: calc(100% - 2 * var(--tsp-innerpadding-top));
  position: absolute;
  top: calc(1 * var(--tsp-innerpadding-top));
  left: 0px;
  display: flex;
  flex-direction: row;
  transition: left 0.9s ease-in-out;
}
.textpage{
  width: 90%;
  height: 100%;
  color: var(--tsp-main-color,#fff);
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 90%;
  margin-left: 5%;
  margin-right: 5%;
  user-select: none;
  font-size: var(--tsp-font-size,12px);
  transition: opacity 0.3s;
}
.textoff{
    opacity:0.1;
}
.pagination{
  position: absolute;
  bottom: var(--tsp-paging-padding-bottom,15px);
  width: 100%;
  color: var(--tsp-main-color,#fff);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.pagebtn{
  margin: var(--tsp-pageicon-margin, 10px);
  user-select: none;
  cursor: pointer;
}
.pagebtn > i {
  font-size: var(--tsp-pageicon-size, 12px);
}
.closeIconViewbox{
    width: var(--tsp-closeicon-width,21.5px);
    height: var(--tsp-closeicon-height,18px);
    fill: #fff;
    transition: all 0.6s ease-in-out;
    transform: var(--tsp-closeicon-transform-close);
}
.closeIcon{
    position: absolute;
    top: var(--tsp-closeicon-top,auto);
    left: var(--tsp-closeicon-left,auto);
    right: var(--tsp-closeicon-right,auto);
    bottom: var(--tsp-closeicon-bottom,auto);
    height: 100%;
    cursor: pointer;
    display: flex;
    align-items: var(--tsp-closeicon-align);
}
.openIcon{
    /* transform: rotate(180deg); */
    transform: var(--tsp-closeicon-transform-open);
}
</style>