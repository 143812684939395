<template>
  <div class="app-root">
    <!-- DEBUG UI VIEW -->
    <DebugUI :hide="!showDebug" />
    <!-- HEADER -->
    <div class="header">
        <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 121.69 54.519" class="header-logo">
          <g id="Gruppe_1" data-name="Gruppe 1" transform="translate(462.744 235.148)">
            <path id="Pfad_3" data-name="Pfad 3" d="M146.891-65.967v-2.847h7.317v6.423a8.637,8.637,0,0,1-7.176,3.365c-4.988,0-8.776-3.459-8.776-8.517a8.574,8.574,0,0,1,8.776-8.518,8.877,8.877,0,0,1,6.918,3.247l-2.329,2.024a6.069,6.069,0,0,0-4.589-2.329,5.275,5.275,0,0,0-5.176,5.576c0,3.294,2.048,5.576,5.176,5.576a5.644,5.644,0,0,0,3.812-1.53v-2.47Z" transform="translate(-530.192 -140.344)" fill="#fff"/>
            <path id="Pfad_4" data-name="Pfad 4" d="M297.807-73.116c-2.918,0-5.035,2.447-5.035,5.576s2.117,5.576,5.035,5.576c2.894,0,5.011-2.446,5.011-5.576s-2.117-5.576-5.011-5.576m0,14.094a8.5,8.5,0,0,1-8.635-8.517,8.5,8.5,0,0,1,8.635-8.517,8.491,8.491,0,0,1,8.611,8.517,8.492,8.492,0,0,1-8.611,8.517" transform="translate(-663.328 -140.347)" fill="#fff"/>
            <path id="Pfad_5" data-name="Pfad 5" d="M462.618-57.191,455.065-67.5v10.306H451.7V-73.66h2.964l7.624,10.329V-73.66h3.364v16.469Z" transform="translate(-806.708 -142.461)" fill="#fff"/>
            <path id="Pfad_6" data-name="Pfad 6" d="M-360.2-57.191V-64.25h-6.988v7.059h-3.492V-73.66h3.492V-67.1h6.988V-73.66h3.5v16.469Z" transform="translate(-81.218 -142.461)" fill="#fff"/>
            <path id="Pfad_7" data-name="Pfad 7" d="M-234.084-57.127V-73.6H-223.2v2.846h-7.416v3.718h7.416v2.846h-7.416v4.212h7.416v2.847Z" transform="translate(-201.72 -142.518)" fill="#fff"/>
            <path id="Pfad_8" data-name="Pfad 8" d="M-121.869-57.2l-3.694-5.647-3.717,5.647H-133.4l5.741-8.376-5.505-8.095h4.235l3.459,5.341,3.483-5.341h4.117l-5.505,8.047,5.741,8.424Z" transform="translate(-290.543 -142.449)" fill="#fff"/>
            <path id="Pfad_9" data-name="Pfad 9" d="M14.491-63H18.37l-1.882-5.529h-.023Zm5.852,5.812L19.3-60.25H13.515l-1.091,3.059H8.731L15.374-73.66h2.694l6.108,16.469Z" transform="translate(-415.927 -142.462)" fill="#fff"/>
            <path id="Pfad_10" data-name="Pfad 10" d="M-440.765-184.735-459.251-196.2v-23.375l18.485-11.464,7.352,4.559,3.313-2.053-10.665-6.613-21.979,13.63v27.259l21.979,13.63,10.665-6.613-3.312-2.053Z" transform="translate(0 -0.002)" fill="#1ba293"/>
            <path id="Pfad_11" data-name="Pfad 11" d="M-281.729-196.2v1.942l21.979,13.63,21.978-13.63V-196.2h-3.494l-18.484,11.463L-278.232-196.2Zm0-23.375v-1.942l21.979-13.63,21.978,13.63v1.942h-3.494l-18.484-11.463-18.482,11.463Z" transform="translate(-159.689 0)" fill="#127e87"/>
            <path id="Pfad_12" data-name="Pfad 12" d="M141.6,99.377h.617c.823,0,1.44-.314,1.44-1.192a1.155,1.155,0,0,0-1.234-1.242H141.6Zm.866-3.952a2.737,2.737,0,1,1,0,5.471H141.6v3.312h-1.548V95.425Z" transform="translate(-531.777 -291.626)" fill="#fff"/>
            <path id="Pfad_13" data-name="Pfad 13" d="M200,100.069c0,2.76-.909,4.291-3.074,4.291-2.122,0-3.032-1.53-3.032-4.291V95.427h1.548v4.88c0,1.682.584,2.485,1.5,2.485s1.5-.8,1.5-2.485v-4.88H200Z" transform="translate(-579.278 -291.627)" fill="#fff"/>
            <path id="Pfad_14" data-name="Pfad 14" d="M261.426,99.189h.606c.78,0,1.364-.327,1.364-1.091a1.1,1.1,0,0,0-1.159-1.154h-.811Zm.855-3.764a2.6,2.6,0,0,1,2.771,2.635,2.483,2.483,0,0,1-1.645,2.446c.67,1.092,1.8,2.886,2.316,3.7h-1.949l-2.078-3.5h-.271v3.5h-1.548V95.425Z" transform="translate(-637.485 -291.626)" fill="#fff"/>
            <path id="Pfad_15" data-name="Pfad 15" d="M321.755,100.069c0,2.76-.909,4.291-3.074,4.291-2.122,0-3.032-1.53-3.032-4.291V95.427H317.2v4.88c0,1.682.584,2.485,1.5,2.485s1.5-.8,1.5-2.485v-4.88h1.548Z" transform="translate(-686.685 -291.627)" fill="#fff"/>
            <path id="Pfad_16" data-name="Pfad 16" d="M380.954,95.681a.893.893,0,0,0-.985.878c0,.678.715.866,1.3,1.1,1.072.427,2.36,1,2.36,2.849a2.7,2.7,0,0,1-2.868,2.722,3.687,3.687,0,0,1-2.9-1.468l1.028-1.166a2.447,2.447,0,0,0,1.884,1.1,1.1,1.1,0,0,0,1.2-1.117c0-.753-.692-1.041-1.461-1.368-.985-.426-2.089-.928-2.089-2.61a2.347,2.347,0,0,1,2.479-2.458,3.627,3.627,0,0,1,2.512,1.041l-.888,1.279a2.4,2.4,0,0,0-1.569-.79" transform="translate(-741.565 -290.502)" fill="#fff"/>
          </g>
        </svg>
        <div class="header-menu" @click="showImprint=!showImprint">
          <i class="fas fa-info-circle"></i>
        </div>
    </div>
    <!-- IMPRINT -->
    <div class="imprint-container" v-if="showImprint">
      <div class="imprint-close" @click="showImprint=false">
        <i class="fas fa-times-circle"></i>
      </div>
      <div class="imprint-title">Imprint</div>   
      <div class="imprint-copy">
        Hexagon Purus ASA<br>
        Korsegata 4B<br>
        6002 Aalasund<br>
        <br>
        T: +47 70 30 44 50<br>
        <br>
        contact(at)hexgonpurus.com<br>
        www.hexagonpurus.com<br>
        <br>
        <span class="imprint-em">Management: </span><br>Morten Holum (President)<br>
        <span class="imprint-em">Headquarters: </span><br>Aalesund<br>
        <span class="imprint-em">Registration number/VAT: </span><br>919 317 558<br>
        <span class="imprint-em">VAT ID number: </span><br>919317558MVA<br>
      </div>
      
    </div>
    <!-- WELCOME SCREEN AND PREPARING 3D -->
    <div v-if="preparing" class="welcome-root">
      
      
      <div v-if="welcome" class="wel-text" >
        <span class="title">Experience our zero emission technology!<br><br></span> 
          In this 3D truck experience, you will learn how the energy transforms in a Battery Electric Vehicle (BEV) and Fuel Cell Electric Vehicle (FCEV), and how our components interact to create zero emission mobility.
          <br><br>
         <span class="title"> To navigate:<br></span>
          Drag, rotate or pinch on your device to explore the virtual environment. Click on the yellow arrows to move closer to the different points of interest: our videos or the 3D truck. Navigate between the Battery Electric Vehicle or Fuel Cell Electric Vehicle tabs in front of the truck and enjoy the experience!
      </div>
      <!-- PRELOADING 3D SCENE -->
      <div v-if="ispreloading" class="preloadscene">
        <div class="progressbar-container">
          <div class="progressbar" :style="{ width: 100 * preloadprogress + '%' }"></div>          
        </div>
        <div class="preloadinfo">Creating 3D world...</div>
        <div v-if="errorMsg!==''" v-html="errorMsg"></div>
      </div>
      <!-- LOADING 3D CONTENTS -->
      <div v-if="istextureloading" class="preloadcontent">
        <div class="progressbar-container">
          <div class="progressbar" :style="{ width: 100 * textureprogress + '%' }"></div>          
        </div>
        <div class="preloadinfo">Loading content...</div>
        <div v-if="errorMsg!==''" v-html="errorMsg"></div>
      </div>
      <!-- Start Button -->
      <div v-if="welcome && showCanvas && texturescomplete" class="wel-action">
        <div class="action-input">
          <button class="start-btn" @click="startClick()">START</button>
        </div>
      </div>
    </div>
    <!-- UI -->
    <div class="ui-footer" :class="{ 'ui-footer-off' : waypoint!=='truck'}">
      <MenuBar :menudata="menudata" @changeLvl1="changeModel" @changeLvl2="changeState"  ref="truckmenu" />
    </div>
    <!-- TESTING TEXTSLIDEPAGER -->
      <TextSlidePager class="tsp" :contents="textcontents" ref="tsp" />
    <!-- 3D CANVAS -->
    <canvas id='application-canvas' :style="{display : showCanvas?'block':'none'}" class="fill-mode-FILL_WINDOW" ref="pccanvas" tabindex="0" onselectstart="return false;"></canvas>
  </div>
</template>

<script>
import MenuBar from './components/MenuBar.vue';
import TextSlidePager from './components/TextSlidePager.vue';
import DebugUI from './components/DebugUI.vue';

export default {
  name: "App",
  components:{
    MenuBar,
    TextSlidePager,
    DebugUI
  },
  props: {
    
  },
  data: () => {
    return {
      allowDebug: false,
      showDebug: false,
      showImprint: false,
      settingsmanager:{},
      errorMsg:'',
      showCanvas: false,
      ischeckingsession:false,
      ispreloading:false,
      isconfigloading:false,
      welcome:true,
      preparing:true,
      preloadprogress:0,
      config:{},
      istextureloading:false,
      textureprogress:0,
      texturescomplete:false,
      waypoint:'',
      model:'',
      modelstate:'',
      menudata:{}
    };
  },
  computed:{
    textcontents:function(){
      if(!this.menudata)return [];
      if(!this.menudata[this.model])return [];
      if(this.modelstate==='')return[];
      var self = this;
      var infos = this.menudata[this.model].states.find(s => 
          s.value===self.modelstate
      );
      return infos!==undefined?infos.infos?infos.infos:[]:[];
    }
  }
  ,
  methods: {
    startClick(){
      this.preparing = false;
      window.app.fire('video:unmute');
    },
    getUrlParameterByName(name, url) {
        if (!url) url = window.location.href;
        name = name.replace(/[[\]]/g, "\\$&");
        var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return null;
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    },
    checkSession() {
      this.createApp();
      this.configurePcApp();
      
    },
    loadconfig(){
      this.istextureloading = true;
      var self = this;
      fetch('./content.json')
      .then((response)=>{if(response.ok)return response.json()})
      .then((result)=>{
        self.config = result;
        self.menudata = result.truckdata;
        //
        //this triggers the pc internal loading of textures and assets
        window.app.fire('config:loaded',self.config);
        
      });
      
    },
    createInputDevices:function (canvas) {
        var devices = {
            elementInput: new window.pc.ElementInput(canvas, {
                useMouse: window.INPUT_SETTINGS.useMouse,
                useTouch: window.INPUT_SETTINGS.useTouch
            }),
            keyboard: window.INPUT_SETTINGS.useKeyboard ? new window.pc.Keyboard(window) : null,
            mouse: window.INPUT_SETTINGS.useMouse ? new window.pc.Mouse(canvas) : null,
            gamepads: window.INPUT_SETTINGS.useGamepads ? new window.pc.GamePads() : null,
            touch: window.INPUT_SETTINGS.useTouch && window.pc.platform.touch ? new window.pc.TouchDevice(canvas) : null
        };

        return devices;
    },
    createApp(){
        var canvas = this.$refs.pccanvas;
        var devices = this.createInputDevices(canvas);

        try {
            var app = new window.pc.Application(canvas, {
                elementInput: devices.elementInput,
                keyboard: devices.keyboard,
                mouse: devices.mouse,
                gamepads: devices.gamepads,
                touch: devices.touch,
                graphicsDeviceOptions: window.CONTEXT_OPTIONS,
                assetPrefix: window.ASSET_PREFIX || "",
                scriptPrefix: window.SCRIPT_PREFIX || "",
                scriptsOrder: window.SCRIPTS || []
            });
            window.app = app;
            //APP listeners
            var self = this;
            app.once('preload:start',()=>{self.ispreloading=true;});
            app.on('preload:end', function () {
                app.off('preload:progress');
            });
            app.on('preload:progress', (p)=>{self.preloadprogress=Math.min(1, Math.max(0, p));});
            app.on('start', ()=>{self.ispreloading=false;self.showCanvas=true;});
        } catch (e) {
            if (e instanceof window.pc.UnsupportedBrowserError) {
                this.errorMsg='This page requires a browser that supports WebGL.<br/>' +
                    '<a href="http://get.webgl.org">Click here to find out more.</a>';
            } else if (e instanceof window.pc.ContextCreationError) {
                this.errorMsg="It doesn't appear your computer can support WebGL.<br/>" +
                    '<a href="http://get.webgl.org/troubleshooting/">Click here for more information.</a>';
            } else {
                this.errorMsg='Could not initialize application. Error: ' + e;
            }

            return;
        }
    },
    configurePcApp(){
      var app = window.app;
      var self = this;
      app.configure(window.CONFIG_FILENAME, function (err) {
            if (err) {
                self.errorMsg=err;
            }
            // do the first reflow after a timeout because of
            // iOS showing a squished iframe sometimes
            setTimeout(function () {
                self.reflow();
                window.addEventListener('resize', self.reflow, false);
                window.addEventListener('orientationchange', self.reflow, false);
                app.preload(function (err) {
                    if (err) {
                        self.errorMsg=err;
                    }
                    app.loadScene(window.SCENE_PATH, function (err, scene) {
                        if (err) {
                            self.errorMsg=err;
                        }
                        self.scene = scene;
                        self.addAppListeners();
                        app.start();
                        self.loadconfig();
                    });
                });
            });
        });
    },
    reflow() {
      var app = window.app;
      var canvas = this.$refs.pccanvas;
      var pc = window.pc;
      app.resizeCanvas(canvas.width, canvas.height);
      canvas.style.width = '';
      canvas.style.height = '';
      
      var fillMode = app._fillMode;

      if (fillMode == pc.FILLMODE_NONE || fillMode == pc.FILLMODE_KEEP_ASPECT) {
          if ((fillMode == pc.FILLMODE_NONE && canvas.clientHeight < window.innerHeight) || (canvas.clientWidth / canvas.clientHeight >= window.innerWidth / window.innerHeight)) {
              canvas.style.marginTop = Math.floor((window.innerHeight - canvas.clientHeight) / 2) + 'px';
          } else {
              canvas.style.marginTop = '';
          }
      }
      if(this.$refs.tsp)this.$refs.tsp.reset();
    },
    onconfigprogress(p){
      this.textureprogress = p;
    },
    onconfigcomplete(){
      this.texturescomplete = true;
      this.istextureloading = false;
      var app = window.app;
      var canvas = this.$refs.pccanvas;
      app.fire('remote:resizecanvas',canvas.width,canvas.height);
      // setTimeout(()=>this.showCanvas = true,300);
    },
    onconfigerror(err){
      //err is object {type,err}
      this.errorMsg = err.err;
    },
    addAppListeners(){
      var app = window.app;
      app.on('config:progress',this.onconfigprogress);
      app.on('config:complete',this.onconfigcomplete);
      app.on('config:error',this.onconfigerror);
      app.on('hexagon:truck:model:set',this.setModel);
      app.on('hexagon:truck:state:set',this.setState);
      app.on('waypoint:set',this.setWaypoint);
    },
    setModel(n){
      this.model = n;
      this.$refs.truckmenu.clickedLevel1(n);
      if(window._paq && n!==''){
        window._paq.push(['trackEvent','truckmodel',''+n]);
        // console.log('tracked event truckmodel '+n);
      }
    },
    setState(n){
      this.modelstate = n;
      this.$refs.truckmenu.clickedLevel2(n);
      if(window._paq && n!= ''){
        window._paq.push(['trackEvent','truckflow',''+this.model+'-'+n]);
        // console.log('tracked event truckflow '+this.model+'-'+n);
      }
    },
    setWaypoint(n){
      this.waypoint = n;
      //matomo tracking
      if(window._paq && n!==''){
        window._paq.push(['trackEvent','waypoint',''+n]);
        // console.log('tracked event waypoint '+n);
      }
    }
    ,
    changeModel(n){
      window.app.fire('hexagon:truck:model:change',n.toLowerCase());
    },
    changeState(n){
      window.app.fire('hexagon:truck:state:change',n.toLowerCase());
    }
  },
  mounted:function(){
    if(this.allowDebug){
      document.addEventListener('keyup',(e)=>{
        if(e.key==='d'){
          // console.log('d pressed '+this.showDebug);
          this.showDebug = ! this.showDebug;
        }
      });
    }
    this.showDebug = this.getUrlParameterByName('debug');
  },
  watch:{
    // showCanvas(n,o){
    //   console.log(`show canvas changed from ${o} to ${n}`);
    // }
  }
};
</script>

<style>
.app-root {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background:transparent;
}

/* HEADER ELEMENTS */
.header{
  background-color: var(--hexagon-teal);
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  height: var(--header-height,90px);
  z-index: 100;
  display:flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.header-logo{
  width: var(--header-logo-width, 121px);
  height: var(--header-logo-height, 54.5px);
  margin: var(--header-logo_margin,0px 0px 0px 31px); 
  padding: 0px;
}
.header-menu{
  margin: var(--header-menu-margin,0px 31px 0px 0px);
  display:flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  font-size: var(--header-menu-font-size);
}
.imprint-container{
  position: absolute;
  top: var(--imprint-top);
  bottom: var(--imprint-bottom);
  left: var(--imprint-left);
  right: var(--imprint-right);
  background-color: var(--imprint-bg);
  border: var(--imprint-border);
  padding: var(--imprint-padding);
  z-index:1500;
  box-shadow: var(--imprint-shadow);
  color: var(--imprint-color);
  display: flex;
  flex-direction: column;
}
.imprint-title{
  color: var(--imprint-title-color,inherit);
  font-size: var(--imprint-title-font-size);
  margin-bottom: var(--imprint-title-margin-bottom,5px);
}
.imprint-copy{
  font-size: var(--imprint-copy-font-size);
  column-count: var(--imprint-copy-columns);
}
.imprint-close{
  /* position: absolute; */
  top: var(--imprint-close-top);
  font-size: var(--imprint-close-size);
  align-self:center;
}
.imprint-em{
  font-family: 'SoleilBold' ,sans-serif;
}

/* LOADING SCREEN */
.welcome-root {
  position: absolute;
  left: var(--welcome-sides,100px);
  right: var(--welcome-sides,100px);
  top: var(--welcome-top,100px);
  height: var(--welcome-height,480px);
  background-color: var(--hexagon-turquoise);
  opacity: 0.8;
  z-index: 94;
  clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - 87px), calc(100% - 150px) 100%, 0% 100%);/* the factor is approx. 0.577 */
  padding: var(--welcome-padding,50px 100px 0px 100px);
}
.wel-text{
  font-size: var(--welcome-fontsize, 26px);
  line-height: var(--welcome-lineheight, 17px);
}
.title{
  font-family: SoleilBold, Arial, sans-serif;
}

.start-btn {
  width: var(--btn-width,200px);
  height: var(--btn-height,50px);
  border: 2px solid #fff; 
  background: none;
  font-size: var(--welcome-fontsize, 26px);
  color: #fff;
  outline: none;
  cursor: pointer;
  -webkit-text-size-adjust: none;
  position: absolute;
  bottom: var(--btn-bottom,50px);
}

.ui-footer{
  position: absolute;
  bottom: 2px;
  left: 0px;
  right: 0px;
  height: var(--menu-height,85px);
  z-index: 90;
  pointer-events: none;
  /* transition: all 0.4s ease-in-out; */
}
.ui-footer-off{
  /* bottom:-87px !important; */
  opacity: 0;
  display: none;
}
/* PLAYCANVAS ELEMENTS */
#application-canvas {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    /* background:transparent; */
}
#application-canvas.fill-mode-FILL_WINDOW {
    width: 100%;
    height: 100%;
    margin: 0;
}

canvas:focus {
    outline: none;
}
.preloadscene{
  /* grid-area: content-start / content-left / content-end / content-right; */
  margin-top: 15px;
  font-size: 0.8rem;
  -webkit-text-size-adjust: none;
}
.preloadcontent{
  /* grid-area: action-start / action-left / action-end / action-right; */
  margin-top: 15px;
  font-size: 0.8rem;
  -webkit-text-size-adjust: none;
}
.progressbar-container{
  background-color: #ffffff66;
  height: 3px;
}
.progressbar{
  background-color: #fff;
  height: 3px;
}
.preloadinfo{
  color: #fff;
  font-size: 0.8rem;
  -webkit-text-size-adjust: none;
}


.tsp{
  z-index:80;
}


</style>

